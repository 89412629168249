import React from "react";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import RegistrationForm from "../../../../pages/register/components/RegistrationForm";
import Trans from "../../../../components/Translate";

const UserContainer = ({ loading, user, isAdmin, statusOptions }) => {
  const isAdminUpdating = user && isAdmin;
  return (
    <div className="container">
      {!isAdminUpdating && (
        <h1 className="content-header">
          <Trans i18nKey={"USER_PROFILE"} />
        </h1>
      )}
      {loading ? (
        <div className="container pt-3">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <LoadingSpinner />
          </div>
        </div>
      ) : (
        <RegistrationForm
          existingUserData={user}
          isAdmin={isAdmin}
          statusOptions={statusOptions}
        />
      )}
    </div>
  );
};

UserContainer.defaultProps = {
  isAdmin: false
};

export default UserContainer;
