import React from "react";
import { graphql } from "gatsby";
import { UserConsumer } from "../../contexts/User.js";
import Layout from "../../layouts/default";
import UserContainer from "./components/UserContainer";
import { t } from "../../components/Translate";

const Profile = ({ location, userFromAdmin, loading }) => {
  return (
    <Layout
      metaTitle={t("User Profile | Update Your Personal Information")}
      pageName={"profile"}
    >
      <UserConsumer>
        {({ state }) => {
          const me = state?.me;
          const meQueryLoading = state?.meQueryLoading;
          return <UserContainer user={me} loading={meQueryLoading} />;
        }}
      </UserConsumer>
    </Layout>
  );
};
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Profile;
